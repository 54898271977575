import { hysteriFetch, wrappers } from 'hysterics';
import { isBrowser } from 'ws-scripts/modules/environment';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { publishEvent } from './pubsub';
import { validateZip } from './validate-zip';

const locationServiceUtilFetcher = hysteriFetch(
	'ws-inv-data-location-service.rest-v1'
);

export const getClosestAccountsRequestBody = ({
	accountId,
	locale,
	postalCode,
	range,
	excludeByRange,
	dealerListSize,
	flags = undefined
}) => {
	const body = {
		accountId,
		locale,
		postalCode,
		range,
		excludeByRange,
		dealerListSize
	};
	return JSON.stringify({
		...body,
		...(flags && { flags })
	});
};

export const getClosestAccountParams = ({
	postalCode,
	range,
	excludeByRange,
	dealerListSize
}) => {
	const params = new URLSearchParams();

	Object.entries({
		postalCode,
		range,
		excludeByRange,
		dealerListSize
	}).forEach(([key, val]) => {
		if (val === false || !!val) {
			params.set(key, val);
		}
	});

	const paramStr = params.toString();
	return paramStr ? `?${paramStr}` : '';
};

export const fetchClosestAccounts = async (requestBody) => {
	const fetchHeaders = new Headers();
	fetchHeaders.append('Accept', 'application/json');
	fetchHeaders.append('Content-Type', 'application/json');

	const { flags, postalCode, prefs } = requestBody;
	const body = getClosestAccountsRequestBody(requestBody);

	try {
		const locationResponse = await locationServiceUtilFetcher(
			'/api/widget/ws-inv-data/getClosestAccounts',
			{
				method: 'POST',
				body,
				headers: fetchHeaders
			},
			{
				[wrappers.timeout.contextKey]: {
					timeout: flags['ws-inv-data-location-service-fetch-timeout']
				},
				[wrappers.retry.contextKey]: {
					retries: flags['ws-inv-data-location-service-fetch-retries']
				}
			}
		);
		if (locationResponse && locationResponse.length) {
			if (isBrowser && window.DDC.InvData) {
				const closestAccounts =
					window.DDC.InvData.closestAccounts || {};
				closestAccounts.data = locationResponse;
				closestAccounts.appliedParams = {
					postalCode: postalCode || locationResponse[0]?.postalCode
				};

				const { showYourStore, geoLocationEnhanced, showLocationTab } =
					prefs;
				closestAccounts.prefs = {
					showYourStore,
					geoLocationEnhanced,
					showLocationTab
				};
				window.DDC.InvData.closestAccounts = closestAccounts;
				publishEvent('ws-inv-data/closestAccounts');
			}
		} else if (validateZip(postalCode)) {
			setNewRelicCustomAttribute(
				'SRP ERROR',
				`ws-inv-data closest dealers request failed.\n${locationResponse}`
			);
			addNewRelicPageAction(
				'WS INV DATA CLOSEST DEALERS REQUEST FAILED',
				{
					errorMsg: locationResponse
				}
			);
		}
		return locationResponse;
	} catch (err) {
		return `Error retrieving location: ${err.message}`;
	}
};
