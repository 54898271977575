import { addNewRelicPageAction } from 'ddc-new-relic';

/**
 * This helper method takes in an array of objects and reduces it to a flat object. Mutiple values are joined into a comma separated string
 * eg: [{id: 'foo', values: [{value: 'bar'}, {value: 'baz'}]}, {id: 'alpha', values: [{value: 'beta'}]}] => {foo: 'bar, baz', alpha: 'beta'}
 * @param {object} appliedFilters: an object that represents all applied filters
 * @returns {object} a flattened version of appliedFilters
 */
export const getQueryFacets = (appliedFilters = []) =>
	/* eslint-disable no-param-reassign */
	appliedFilters.reduce((queryFacetsObj, { id, values }) => {
		queryFacetsObj[id] = values
			.reduce((queries, { value }) => {
				return queries.concat(value);
			}, [])
			.join(', ');
		return queryFacetsObj;
	}, {});

const updateDdcDataLayer = ({
	trackingData,
	sortBy = [],
	totalCount,
	pageSize,
	pageStart,
	queryFacets
}) => {
	if (
		window.DDC.dataLayer &&
		window.DDC.dataLayer.page &&
		window.DDC.dataLayer.page.attributes
	) {
		window.DDC.dataLayer.vehicles = trackingData;
		window.DDC.dataLayer.page.attributes.vehicleListingPageSort =
			sortBy[0] || null;
		window.DDC.dataLayer.page.attributes.vehicleResultCount = totalCount;
		window.DDC.dataLayer.page.attributes.vehicleCountPerPage = pageSize;
		window.DDC.dataLayer.page.attributes.vehicleCurrentPage =
			pageStart / pageSize + 1;
		if (queryFacets) {
			window.DDC.dataLayer.page.queryFacets = queryFacets || {};
		}
	} else {
		addNewRelicPageAction('WS INV DATA DATALAYER NOT DEFINED', {
			trackingData,
			queryFacets,
			pageSize,
			pageStart,
			totalCount,
			sortBy
		});
	}
};

export const updateGtmDataLayerFromWIS = (
	eventType,
	eventData,
	queryFacets,
	totalCount,
	sortBy
) => {
	const isTypedSearch = eventData?.clickedFacetName === 'search';

	if (window?.DDC && typeof window.DDC.pushToGtmDataLayer === 'function') {
		if (eventType === 'facet') {
			// this event fires on any other facet interaction
			window.DDC.pushToGtmDataLayer({
				event: 'ddc.inventory.filterSearch.click',
				eventData: {
					resultCount: totalCount,
					facets: queryFacets,
					sortBy,
					...eventData
				}
			});
		} else if (eventType === 'search' || (!eventType && isTypedSearch)) {
			// this event only fires when text search results in a search param
			window.DDC.pushToGtmDataLayer({
				event: 'ddc.inventory.typedSearch',
				eventData: {
					resultCount: totalCount,
					queryFacets,
					sortBy
				}
			});
		}

		if (eventType !== 'sort' && eventType !== 'spellcheck') {
			// this event fires on page load and on every facet/search interaction.
			window.DDC.pushToGtmDataLayer({
				event: 'ddc.inventory.filterSearch',
				eventData: {
					resultCount: totalCount,
					queryFacets,
					sortBy
				}
			});
		}
	}
};

/**
 * Method that fires tracking events to GTMDataLayer
 * @param {object} appliedFilters an array that represents all applied filters
 * @param {object} inventory an object that represents the inventory on the page
 * @param {object} sortBy an array that represents the applied sort by filters on the page
 * @param {string} eventType optional attribute assigned when event is published (can be empty string/undefined)
 * @param {object} eventData an object that represents the tracking event data
 */
export const setDataLayerTracking = (
	appliedFilters = [],
	sortBy = [],
	inventory = {},
	eventType,
	eventData
) => {
	const {
		pageInfo: { totalCount = 0, pageSize, pageStart },
		trackingData
	} = inventory;

	const queryFacets = getQueryFacets(appliedFilters);

	const dataLayerTrackingUpdate = {
		trackingData,
		sortBy,
		totalCount,
		pageSize,
		pageStart,
		queryFacets
	};

	updateDdcDataLayer(dataLayerTrackingUpdate);

	updateGtmDataLayerFromWIS(
		eventType,
		eventData,
		queryFacets,
		totalCount,
		sortBy
	);
};

const getAttributeValue = (attrs, name) => {
	let value;
	if (attrs) {
		value = attrs.find((attr) => attr.name === name)?.value;
	}
	return value;
};

const getDealerCodes = (dealerCodesObj) => {
	const response = {};
	dealerCodesObj.forEach((item) => {
		// noinspection JSDeprecatedSymbols
		response[item.codeType] = item.code;
	});
	return response;
};

const getInventoryType = (type, certified) => {
	return certified && type === 'used' ? 'certified' : type;
};

const getFlattenedPricingObject = (dprice = []) => {
	return dprice.reduce((acc, price) => {
		acc[price.typeClass] = price.value;
		if (price.isFinalPrice === true) {
			acc.finalPrice = price.value;
		}
		return acc;
	}, {});
};

export const getVehicleTrackingData = (wisResponse) => {
	let index = 0;
	return wisResponse.inventory.map((vehicle) => {
		index += 1; // indexPosition is a base one array
		// noinspection SpellCheckingInspection
		const vehicleData = {
			accountId: vehicle.accountId,
			address: wisResponse.accounts[vehicle.accountId].address,
			autodataCaId: getAttributeValue(
				vehicle.trackingAttributes,
				'autodataCaId'
			),
			autodataUsId: getAttributeValue(
				vehicle.trackingAttributes,
				'autodataUsId'
			),
			bodyStyle: vehicle.bodyStyle,
			certified: vehicle.certified,
			chromeId: vehicle.chromeId,
			cityFuelEfficiency: getAttributeValue(
				vehicle.trackingAttributes,
				'cityFuelEconomy'
			),
			classification: vehicle.classification,
			dealerCodes: getDealerCodes(
				wisResponse.accounts[vehicle.accountId].dealerCodes
			),
			doors: getAttributeValue(vehicle.trackingAttributes, 'doors'),
			driveLine: getAttributeValue(
				vehicle.trackingAttributes,
				'driveLine'
			),
			engine: getAttributeValue(vehicle.trackingAttributes, 'engine'),
			engineSize: getAttributeValue(
				vehicle.trackingAttributes,
				'engineSize'
			),
			deliveryDateRange: getAttributeValue(
				vehicle.trackingAttributes,
				'deliveryDateRange'
			),
			exteriorColor: getAttributeValue(
				vehicle.trackingAttributes,
				'exteriorColor'
			),
			fuelType: getAttributeValue(vehicle.trackingAttributes, 'fuelType'),
			highwayFuelEfficiency: getAttributeValue(
				vehicle.trackingAttributes,
				'highwayFuelEconomy'
			),
			images: vehicle.images ? [vehicle.images[0]] : [],
			indexPosition: index,
			interiorColor: getAttributeValue(
				vehicle.trackingAttributes,
				'interiorColor'
			),
			inventoryDate: vehicle.inventoryDate,
			inventoryType: getInventoryType(vehicle.type, vehicle.certified),
			link: vehicle.link,
			make: vehicle.make,
			model: vehicle.model,
			modelCode: vehicle.modelCode,
			modelYear: vehicle.year,
			newOrUsed: vehicle.type,
			odometer: parseInt(
				getAttributeValue(vehicle.trackingAttributes, 'odometer'),
				10
			),
			oemSourcedMerchandisingStatus: getAttributeValue(
				vehicle.trackingAttributes,
				'oemSourcedMerchandisingStatus'
			),
			oemSerialNumber: getAttributeValue(
				vehicle.trackingAttributes,
				'oemSerialNumber'
			),
			optionCodes: vehicle.optionCodes,
			packageCode: getAttributeValue(
				vehicle.trackingAttributes,
				'packageCode'
			),
			pricing: getFlattenedPricingObject(vehicle?.pricing?.dprice),
			status: vehicle.status,
			stockNumber: vehicle.stockNumber,
			transmission: getAttributeValue(
				vehicle.trackingAttributes,
				'transmission'
			),
			trim: vehicle.trim,
			uuid: vehicle.uuid,
			vin: vehicle.vin,
			year: vehicle.year
		};

		if (vehicle?.trackingPricing !== undefined) {
			Object.keys(vehicle.trackingPricing).forEach((price) => {
				if (vehicle.trackingPricing[price] !== '0') {
					vehicleData[price] = vehicle.trackingPricing[price];
				}
			});
		}

		return vehicleData;
	});
};

export const updateDdcDataLayerFromWIS = (wisResponse, sortBy) => {
	const requestObj = {
		trackingData: getVehicleTrackingData(wisResponse),
		sortBy,
		totalCount: wisResponse.pageInfo.totalCount,
		pageSize: wisResponse.pageInfo.pageSize,
		pageStart: wisResponse.pageInfo.pageStart,
		queryFacets: undefined // queryFacets needs to be published by getFacets request from WIS
	};
	updateDdcDataLayer(requestObj);
};
